exports.components = {
  "component---cache-caches-gatsby-plugin-offline-app-shell-js": () => import("./../../caches/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---cache-caches-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-404-en-js": () => import("./../../../src/pages/404.en.js" /* webpackChunkName: "component---src-pages-404-en-js" */),
  "component---src-pages-404-es-js": () => import("./../../../src/pages/404.es.js" /* webpackChunkName: "component---src-pages-404-es-js" */),
  "component---src-pages-404-it-js": () => import("./../../../src/pages/404.it.js" /* webpackChunkName: "component---src-pages-404-it-js" */),
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-404-pt-js": () => import("./../../../src/pages/404.pt.js" /* webpackChunkName: "component---src-pages-404-pt-js" */),
  "component---src-pages-contact-us-en-js": () => import("./../../../src/pages/contact-us.en.js" /* webpackChunkName: "component---src-pages-contact-us-en-js" */),
  "component---src-pages-contactanos-es-js": () => import("./../../../src/pages/contactanos.es.js" /* webpackChunkName: "component---src-pages-contactanos-es-js" */),
  "component---src-pages-contate-nos-pt-js": () => import("./../../../src/pages/contate-nos.pt.js" /* webpackChunkName: "component---src-pages-contate-nos-pt-js" */),
  "component---src-pages-contattaci-it-js": () => import("./../../../src/pages/contattaci.it.js" /* webpackChunkName: "component---src-pages-contattaci-it-js" */),
  "component---src-pages-crecimiento-personal-es-js": () => import("./../../../src/pages/crecimiento-personal.es.js" /* webpackChunkName: "component---src-pages-crecimiento-personal-es-js" */),
  "component---src-pages-crescimento-pessoal-pt-js": () => import("./../../../src/pages/crescimento-pessoal.pt.js" /* webpackChunkName: "component---src-pages-crescimento-pessoal-pt-js" */),
  "component---src-pages-crescita-personale-it-js": () => import("./../../../src/pages/crescita-personale.it.js" /* webpackChunkName: "component---src-pages-crescita-personale-it-js" */),
  "component---src-pages-donde-estamos-es-js": () => import("./../../../src/pages/donde-estamos.es.js" /* webpackChunkName: "component---src-pages-donde-estamos-es-js" */),
  "component---src-pages-dove-siamo-it-js": () => import("./../../../src/pages/dove-siamo.it.js" /* webpackChunkName: "component---src-pages-dove-siamo-it-js" */),
  "component---src-pages-estimulacao-neural-pt-js": () => import("./../../../src/pages/estimulacao-neural.pt.js" /* webpackChunkName: "component---src-pages-estimulacao-neural-pt-js" */),
  "component---src-pages-estimulacion-neural-es-js": () => import("./../../../src/pages/estimulacion-neural.es.js" /* webpackChunkName: "component---src-pages-estimulacion-neural-es-js" */),
  "component---src-pages-eventi-it-js": () => import("./../../../src/pages/eventi.it.js" /* webpackChunkName: "component---src-pages-eventi-it-js" */),
  "component---src-pages-eventos-es-js": () => import("./../../../src/pages/eventos.es.js" /* webpackChunkName: "component---src-pages-eventos-es-js" */),
  "component---src-pages-eventos-pt-js": () => import("./../../../src/pages/eventos.pt.js" /* webpackChunkName: "component---src-pages-eventos-pt-js" */),
  "component---src-pages-events-en-js": () => import("./../../../src/pages/events.en.js" /* webpackChunkName: "component---src-pages-events-en-js" */),
  "component---src-pages-gracias-es-js": () => import("./../../../src/pages/gracias.es.js" /* webpackChunkName: "component---src-pages-gracias-es-js" */),
  "component---src-pages-grazie-it-js": () => import("./../../../src/pages/grazie.it.js" /* webpackChunkName: "component---src-pages-grazie-it-js" */),
  "component---src-pages-historia-es-js": () => import("./../../../src/pages/historia.es.js" /* webpackChunkName: "component---src-pages-historia-es-js" */),
  "component---src-pages-historia-pt-js": () => import("./../../../src/pages/historia.pt.js" /* webpackChunkName: "component---src-pages-historia-pt-js" */),
  "component---src-pages-history-en-js": () => import("./../../../src/pages/history.en.js" /* webpackChunkName: "component---src-pages-history-en-js" */),
  "component---src-pages-index-en-js": () => import("./../../../src/pages/index.en.js" /* webpackChunkName: "component---src-pages-index-en-js" */),
  "component---src-pages-index-es-js": () => import("./../../../src/pages/index.es.js" /* webpackChunkName: "component---src-pages-index-es-js" */),
  "component---src-pages-index-it-js": () => import("./../../../src/pages/index.it.js" /* webpackChunkName: "component---src-pages-index-it-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-index-pt-js": () => import("./../../../src/pages/index.pt.js" /* webpackChunkName: "component---src-pages-index-pt-js" */),
  "component---src-pages-informazioni-legali-it-js": () => import("./../../../src/pages/informazioni-legali.it.js" /* webpackChunkName: "component---src-pages-informazioni-legali-it-js" */),
  "component---src-pages-legal-notice-en-js": () => import("./../../../src/pages/legal-notice.en.js" /* webpackChunkName: "component---src-pages-legal-notice-en-js" */),
  "component---src-pages-meditacao-pt-js": () => import("./../../../src/pages/meditacao.pt.js" /* webpackChunkName: "component---src-pages-meditacao-pt-js" */),
  "component---src-pages-meditacion-es-js": () => import("./../../../src/pages/meditacion.es.js" /* webpackChunkName: "component---src-pages-meditacion-es-js" */),
  "component---src-pages-meditation-en-js": () => import("./../../../src/pages/meditation.en.js" /* webpackChunkName: "component---src-pages-meditation-en-js" */),
  "component---src-pages-meditazione-it-js": () => import("./../../../src/pages/meditazione.it.js" /* webpackChunkName: "component---src-pages-meditazione-it-js" */),
  "component---src-pages-neural-stimulation-en-js": () => import("./../../../src/pages/neural-stimulation.en.js" /* webpackChunkName: "component---src-pages-neural-stimulation-en-js" */),
  "component---src-pages-news-en-js": () => import("./../../../src/pages/news.en.js" /* webpackChunkName: "component---src-pages-news-en-js" */),
  "component---src-pages-nota-legal-es-js": () => import("./../../../src/pages/nota-legal.es.js" /* webpackChunkName: "component---src-pages-nota-legal-es-js" */),
  "component---src-pages-noticias-es-js": () => import("./../../../src/pages/noticias.es.js" /* webpackChunkName: "component---src-pages-noticias-es-js" */),
  "component---src-pages-noticias-pt-js": () => import("./../../../src/pages/noticias.pt.js" /* webpackChunkName: "component---src-pages-noticias-pt-js" */),
  "component---src-pages-notizie-it-js": () => import("./../../../src/pages/notizie.it.js" /* webpackChunkName: "component---src-pages-notizie-it-js" */),
  "component---src-pages-obrigado-pt-js": () => import("./../../../src/pages/obrigado.pt.js" /* webpackChunkName: "component---src-pages-obrigado-pt-js" */),
  "component---src-pages-onde-estamos-pt-js": () => import("./../../../src/pages/onde-estamos.pt.js" /* webpackChunkName: "component---src-pages-onde-estamos-pt-js" */),
  "component---src-pages-personal-growth-en-js": () => import("./../../../src/pages/personal-growth.en.js" /* webpackChunkName: "component---src-pages-personal-growth-en-js" */),
  "component---src-pages-privacidad-es-js": () => import("./../../../src/pages/privacidad.es.js" /* webpackChunkName: "component---src-pages-privacidad-es-js" */),
  "component---src-pages-privacidade-pt-js": () => import("./../../../src/pages/privacidade.pt.js" /* webpackChunkName: "component---src-pages-privacidade-pt-js" */),
  "component---src-pages-privacy-en-js": () => import("./../../../src/pages/privacy.en.js" /* webpackChunkName: "component---src-pages-privacy-en-js" */),
  "component---src-pages-privacy-it-js": () => import("./../../../src/pages/privacy.it.js" /* webpackChunkName: "component---src-pages-privacy-it-js" */),
  "component---src-pages-representante-legal-pt-js": () => import("./../../../src/pages/representante-legal.pt.js" /* webpackChunkName: "component---src-pages-representante-legal-pt-js" */),
  "component---src-pages-stimolazione-neurale-it-js": () => import("./../../../src/pages/stimolazione-neurale.it.js" /* webpackChunkName: "component---src-pages-stimolazione-neurale-it-js" */),
  "component---src-pages-storia-it-js": () => import("./../../../src/pages/storia.it.js" /* webpackChunkName: "component---src-pages-storia-it-js" */),
  "component---src-pages-thanks-en-js": () => import("./../../../src/pages/thanks.en.js" /* webpackChunkName: "component---src-pages-thanks-en-js" */),
  "component---src-pages-uses-neural-stimulation-en-js": () => import("./../../../src/pages/uses-neural-stimulation.en.js" /* webpackChunkName: "component---src-pages-uses-neural-stimulation-en-js" */),
  "component---src-pages-usi-stimolazione-neurale-it-js": () => import("./../../../src/pages/usi-stimolazione-neurale.it.js" /* webpackChunkName: "component---src-pages-usi-stimolazione-neurale-it-js" */),
  "component---src-pages-usos-estimulacao-neural-pt-js": () => import("./../../../src/pages/usos-estimulacao-neural.pt.js" /* webpackChunkName: "component---src-pages-usos-estimulacao-neural-pt-js" */),
  "component---src-pages-usos-estimulacion-neural-es-js": () => import("./../../../src/pages/usos-estimulacion-neural.es.js" /* webpackChunkName: "component---src-pages-usos-estimulacion-neural-es-js" */),
  "component---src-pages-where-we-are-en-js": () => import("./../../../src/pages/where-we-are.en.js" /* webpackChunkName: "component---src-pages-where-we-are-en-js" */),
  "component---src-templates-event-wrapper-js": () => import("./../../../src/templates/EventWrapper.js" /* webpackChunkName: "component---src-templates-event-wrapper-js" */),
  "component---src-templates-news-article-news-article-wrapper-js": () => import("./../../../src/templates/NewsArticle/NewsArticleWrapper.js" /* webpackChunkName: "component---src-templates-news-article-news-article-wrapper-js" */)
}

